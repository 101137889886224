import React from 'react';
import Layout from '../components/layout';

function Infrastructure() {
    return ( 
        <Layout>
            <h1>Infrastructure</h1>
        </Layout>
     );
}

export default Infrastructure;